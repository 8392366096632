<template>
  <div>
    <div class="header">
      <div class="container">
        <h1>Delivery X1</h1>
        <p>{{ this.$t('deliveryx1.header_content') }}</p>
      </div>
    </div>
    <div class="banner container">
      <div class="left">
        <ul>
          <li
            @click="activeId = 0"
            :class="{active:activeId === 0}"
          >DELIVERY X1</li>
          <li
            @click="activeId = 1"
            :class="{active:activeId === 1}"
          >X1 PRO</li>
        </ul>
        <div class="car">
          <el-carousel
            indicator-position="outside"
            :autoplay="false"
            v-show="activeId === 0"
            trigger="click"
            height="500px"
          >
            <el-carousel-item
              v-for="item in x1List"
              :key="item.id"
            >
              <img
                width="100%"
                :src="item.path"
              >
            </el-carousel-item>
          </el-carousel>
          <el-carousel
            indicator-position="outside"
            :autoplay="false"
            v-show="activeId === 1"
            trigger="click"
            height="500px"
          >
            <el-carousel-item
              v-for="item in proList"
              :key="item.id"
            >
              <img
                width="100%"
                :src="item.path"
              >
            </el-carousel-item>
          </el-carousel>
        </div>

      </div>
      <div class="right">
        <h1>{{ this.$t('deliveryx1.banner.title') }}</h1>
        <p>{{ this.$t('deliveryx1.banner.content') }}</p>
        <div class="box">
          <span>{{ this.$t('deliveryx1.banner.end') }}</span>
          <img
            src="../../assets/Deliveryx1/award_RedDotWinner2022.png"
            alt=""
          ><img
            src="../../assets/Deliveryx1/award-a-design-2022-bronze.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="details">
      <el-carousel
        ref="carouselRef"
        indicator-position='none'
        arrow="never"
        :autoplay="false"
        height="933px"
      >
        <el-carousel-item
          v-for="(item) in detialsList"
          :key="item.id"
        >
          <img
            :src="item.path"
            alt=""
          >
        </el-carousel-item>
      </el-carousel>
      <div class="details-box container">
        <h1 :class="{activetext:chooseId === 0}">{{this.$t('deliveryx1.details.title_a')}}</h1>
        <h1 :class="{activetext:chooseId === 1}">{{this.$t('deliveryx1.details.title_b')}}</h1>
        <h1 :class="{activetext:chooseId === 2}">{{this.$t('deliveryx1.details.title_c')}}</h1>
        <ul>
          <li
            @click="chooseHandler(0)"
            :class="{act:chooseId === 0}"
          >{{this.$t('deliveryx1.details.content_title.title_a')}}</li>
          <li
            @click="chooseHandler(1)"
            :class="{act:chooseId === 1}"
          >{{this.$t('deliveryx1.details.content_title.title_b')}}</li>
          <li
            @click="chooseHandler(2)"
            :class="{act:chooseId === 2}"
          >{{this.$t('deliveryx1.details.content_title.title_c')}}</li>
        </ul>
      </div>
    </div>

    <div class="features">
      <div class="container">
        <h1 class="title">{{this.$t('deliveryx1.details.content.title')}}</h1>
        <ul>
          <li>
            <img
              src="../../assets/Deliveryx1/Marker-less-Deployment.png"
              alt=""
            >
            <h2>{{this.$t('deliveryx1.details.content.boxA.title')}}</h2>
            <p>
              <span></span>
              {{this.$t('deliveryx1.details.content.boxA.content')}}
            </p>
          </li>
          <li><img
              src="../../assets/Deliveryx1/weight-sensors.png"
              alt=""
            >
            <h2>{{this.$t('deliveryx1.details.content.boxB.title')}}</h2>
            <p>
              <span></span>
              {{this.$t('deliveryx1.details.content.boxB.content')}}
            </p>
          </li>
          <li><img
              src="../../assets/Deliveryx1/Industry-leading-Algorithm.png"
              alt=""
            >
            <h2>{{this.$t('deliveryx1.details.content.boxC.title')}}</h2>
            <p>
              <span></span>
              {{this.$t('deliveryx1.details.content.boxC.content')}}
            </p>
          </li>
          <li><img
              src="../../assets/Deliveryx1/icon_blue_X1-Navigating-Narrow-Aisles.png"
              alt=""
            >
            <h2>{{this.$t('deliveryx1.details.content.boxD.title')}}</h2>
            <p>
              <span></span>
              {{this.$t('deliveryx1.details.content.boxD.content')}}
            </p>
          </li>
          <li><img
              src="../../assets/Deliveryx1/High-Performance-Battery.png"
              alt=""
            >
            <h2>{{this.$t('deliveryx1.details.content.boxE.title')}}</h2>
            <p>
              <span></span>
              {{this.$t('deliveryx1.details.content.boxE.content')}}
            </p>
          </li>
          <li><img
              src="../../assets/Deliveryx1/Marker-less-Deployment.png"
              alt=""
            >
            <h2>{{this.$t('deliveryx1.details.content.boxF.title')}}</h2>
            <p>
              <span></span>
              {{this.$t('deliveryx1.details.content.boxF.content')}}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <video
      controls
      autoplay
      muted
      loop
      width="100%"
      style="vertical-align: bottom;"
      src="../../assets/Deliveryx1Video/Delivery-X1-Pro-Video.mp4"
    ></video>
    <ul class="intro container">
      <li>
        <div class="intro-video">
          <video
            controls
            autoplay
            muted
            loop
            width="100%"
            style="vertical-align: bottom;"
            src="../../assets/Deliveryx1Video/Bring_productivity_up_and_costs_down.mp4"
          ></video>
        </div>
        <div class="intro-box">
          <span>{{ this.$t('deliveryx1.intro.boxB.header') }}</span>
          <h2>{{ this.$t('deliveryx1.intro.boxB.title') }}</h2>
          <p>{{ this.$t('deliveryx1.intro.boxB.content') }}</p>
          <ul class="intro-list">
            <li><span></span>{{ this.$t('deliveryx1.intro.boxB_list.one') }}</li>
            <li><span></span>{{ this.$t('deliveryx1.intro.boxB_list.two') }}</li>
            <li><span></span>{{ this.$t('deliveryx1.intro.boxB_list.three') }}</li>
            <li><span></span>{{ this.$t('deliveryx1.intro.boxB_list.four') }}</li>
          </ul>
        </div>
      </li>
      <li>
        <div class="intro-box">
          <span>{{ this.$t('deliveryx1.intro.boxC.header') }}</span>
          <h2>{{ this.$t('deliveryx1.intro.boxC.title') }}</h2>
          <p>{{ this.$t('deliveryx1.intro.boxC.content') }}</p>
          <ul class="intro-list">
            <li><span></span>{{ this.$t('deliveryx1.intro.boxC_list.one') }}</li>
            <li><span></span>{{ this.$t('deliveryx1.intro.boxC_list.two') }}</li>
          </ul>
        </div>
        <div class="intro-video">
          <video
            controls
            autoplay
            muted
            loop
            width="100%"
            style="vertical-align: bottom;"
            src="../../assets/Deliveryx1Video/Simplify_your_service_process.mp4"
          ></video>
        </div>
      </li>
      <li>
        <div class="intro-video">
          <video
            controls
            autoplay
            muted
            loop
            width="100%"
            style="vertical-align: bottom;"
            src="../../assets/Deliveryx1Video/Collision-free-and-Spill-proof.mp4"
          ></video>
        </div>
        <div class="intro-box">
          <span>{{ this.$t('deliveryx1.intro.boxA.header') }}</span>
          <h2>{{ this.$t('deliveryx1.intro.boxA.title') }}</h2>
          <p>{{ this.$t('deliveryx1.intro.boxA.content') }}</p>
          <ul class="intro-list">
            <li><span></span>{{ this.$t('deliveryx1.intro.boxA_list.one') }}</li>
            <li><span></span>{{ this.$t('deliveryx1.intro.boxA_list.two') }}</li>
            <li><span></span>{{ this.$t('deliveryx1.intro.boxA_list.three') }}</li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="footer container">
      <img
        src="../../assets/Deliveryx1/40.png"
        alt=""
      >
      <div>
        <h1>{{ this.$t('deliveryx1.footer.title') }}</h1>
        <ul>
          <li>
            <span>{{ this.$t('deliveryx1.footer.list.title_a') }}</span>
            <p>{{ this.$t('deliveryx1.footer.list.content_a') }}<br /><br />{{ this.$t('deliveryx1.footer.list.content_aa') }}</p>
          </li>
          <li>
            <span>{{ this.$t('deliveryx1.footer.list.title_b') }}</span>
            <p>{{ this.$t('deliveryx1.footer.list.content_b') }}</p>
          </li>
          <li>
            <span>{{ this.$t('deliveryx1.footer.list.title_c') }}</span>
            <p>{{ this.$t('deliveryx1.footer.list.content_c') }}</p>
          </li>
          <li>
            <span>{{ this.$t('deliveryx1.footer.list.title_d') }}</span>
            <p>{{ this.$t('deliveryx1.footer.list.content_d') }}</p>
          </li>
          <li>
            <span>{{ this.$t('deliveryx1.footer.list.title_e') }}</span>
            <p>{{ this.$t('deliveryx1.footer.list.content_e') }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeId: 0,
      chooseId: 0,
      x1List: [
        { id: 1, path: require("../../assets/Deliveryx1/x1-one.png") },
        { id: 2, path: require("../../assets/Deliveryx1/x1-two.png") },
        { id: 3, path: require("../../assets/Deliveryx1/x1-three.png") },
        { id: 4, path: require("../../assets/Deliveryx1/x1-four.png") },
        { id: 5, path: require("../../assets/Deliveryx1/x1-five.png") },
      ],
      proList: [
        { id: 1, path: require("../../assets/Deliveryx1/pro-one.png") },
        { id: 2, path: require("../../assets/Deliveryx1/pro-two.png") },
        { id: 3, path: require("../../assets/Deliveryx1/pro-three.png") },
        { id: 4, path: require("../../assets/Deliveryx1/pro-four.png") },
        { id: 5, path: require("../../assets/Deliveryx1/pro-five.png") },
      ],
      detialsList: [
        {
          id: 1,
          name: "one",
          path: require("../../assets/Deliveryx1/details-one.png"),
        },
        {
          id: 2,
          name: "two",
          path: require("../../assets/Deliveryx1/details-two.png"),
        },
        {
          id: 3,
          name: "three",
          path: require("../../assets/Deliveryx1/details-three.png"),
        },
      ],
    };
  },
  mounted() {},
  methods: {
    changeHandler(index) {
      this.$refs.carouselRef.setActiveItem(index);
    },
    //轮播图手动切换
    chooseHandler(id) {
      this.chooseId = id;
      this.changeHandler(id);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 933px;
  background: url("../../assets/Deliveryx1/Smart-Obstacle-Avoidance-D.-min.7b920454.png")
    no-repeat center;
  background-size: cover;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 44px;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 59px;
      color: #ffffff;
      margin-bottom: 30px;
    }
    p {
      font-size: 20px;
      font-family: "Meiryooo";
      font-weight: 400;
      line-height: 40px;
      color: #ffffff;
    }
  }
}
.banner {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin-bottom: 150px !important;
  .left {
    width: 580px;
    overflow: hidden;
    ul {
      margin-top: 150px;
      display: flex;
      justify-content: center;
      li {
        text-align: center;
        width: 80px;
        font-size: 12px;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 40px;
        color: #666666;
        white-space: nowrap;
        margin-right: 20px;
        cursor: pointer;
        transition: 0.5s;
        border-bottom: 2px solid transparent;
      }
      .active {
        color: #000;
        border-color: #0064e6;
      }
    }
    .car {
      height: 565px;
      /deep/.el-carousel {
        .el-carousel__container {
          margin-bottom: 20px;
        }
        .el-carousel__arrow {
        }
        .el-carousel__button {
          width: 10px !important;
          height: 10px !important;
          border-radius: 10px !important;
          background-color: #6a6a6a;
        }
      }
    }
  }
  .right {
    width: 543px;
    padding-top: 200px;
    h1 {
      font-size: 40px;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 60px;
      color: #000000;
      margin-bottom: 24px;
    }
    p {
      font-size: 16px;
      font-family: "Meiryooo";
      font-weight: 400;
      line-height: 36px;
      color: #333333;
      margin-bottom: 61px;
    }
    .box {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        margin-right: 30px;
      }
      img:nth-child(2) {
        width: 82px;
        margin-right: 20px;
      }
      img:last-child {
        width: 30px;
      }
    }
  }
}
.details {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  .details-box {
    width: 1200px;
    height: 100%;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: end;
    h1 {
      margin-top: 120px;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 40px;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 60px;
      color: #ffffff;
      opacity: 0;
      transition: 0.5s;
    }
    .activetext {
      opacity: 1;
    }
    ul {
      font-size: 16px;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 40px;
      color: #ffffff;
      display: flex;
      li {
        width: 120px;
        text-align: center;
        border-bottom: 2px solid transparent;
        margin-right: 35px;
        transition: 0.5s;
        cursor: pointer;
      }
      .act {
        border-color: #0064e6;
      }
    }
  }
}
.features {
  padding: 150px 0 130px 0;
  background-color: #f2f5f8;

  h1 {
    font-size: 40px;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 52px;
    color: #000000;
    text-align: center;
    margin-bottom: 100px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex-shrink: 0;
      background-color: #fff;
      width: 374px;
      height: 260px;
      margin-bottom: 40px;
      padding: 30px;
      box-sizing: border-box;
      img {
        vertical-align: bottom;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 20px;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 30px;
        color: #000000;
        margin-bottom: 18px;
      }
      p {
        font-size: 14px;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 26px;
        color: #000000;
        padding-left: 16px;
        position: relative;
        span {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          border-top: 3px solid #051ad0;
          border-bottom: 3px solid transparent;
          border-left: 3px solid #051ad0;
          border-right: 3px solid transparent;
        }
      }
    }
  }
}
.intro {
  padding-top: 150px;
  li {
    margin-bottom: 150px;
    display: flex;
    .intro-video {
      width: 577px !important;
      flex-shrink: 0;
    }
    .intro-box {
      margin-left: 80px;
      span {
        display: block;
        font-size: 16px;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 36px;
        color: #333333;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 32px;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 48px;
        color: #000000;
        margin-bottom: 30px;
      }
      p {
        font-size: 16px;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 34px;
        color: #333333;
        margin-bottom: 30px;
      }
      .intro-list {
        font-size: 16px;
        font-family: Meiryo;
        font-weight: 400;
        line-height: 36px;
        color: #333333;
        li {
          margin-bottom: 20px;
          position: relative;
          padding-left: 12px;
          span {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            border-top: 3px solid #051ad0;
            border-bottom: 3px solid transparent;
            border-left: 3px solid #051ad0;
            border-right: 3px solid transparent;
          }
        }
      }
    }
  }
  li:nth-child(2) {
    .intro-box {
      margin: 0 80px 0 0;
    }
  }
}
.footer {
  display: flex;
  margin-bottom: 150px !important;
  img {
    flex-shrink: 0;
    vertical-align: bottom;
    margin-right: 0;
  }
  div {
    margin-top: 105px;
    flex-grow: 1;
    h1 {
      font-size: 18px;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 24px;
      color: #000000;
      text-align: center;
      margin-bottom: 50px;
    }
    ul {
      li {
        margin-bottom: 20px;
        display: flex;
        span {
          width: 215px;
          font-size: 16px;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 26px;
          color: #000000;
          flex-shrink: 0;
          text-align: right;
          margin-right: 40px;
        }
        p {
          width: 433px;
          font-size: 16px;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 26px;
          color: #0064e6;
        }
      }
    }
  }
}
</style>