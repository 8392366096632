<template>
  <div>
    <div class="header">
      <h1>Delivery X1</h1>
      <p>{{ this.$t("deliveryx1.header_content") }}</p>
    </div>
    <div class="banner">
      <ul>
        <li :class="{ active: activeId === 0 }" @click="activeId = 0">DELIVERY X1</li>
        <li :class="{ active: activeId === 1 }" @click="activeId = 1">X1 PRO</li>
      </ul>
      <div>
        <el-carousel v-show="activeId === 0" indicator-position="outside">
          <el-carousel-item v-for="item in x1List" :key="item.id">
            <div class="img-car">
              <img width="100%" style="vertical-align: bottom" :src="item.path" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel indicator-position="outside" v-show="activeId === 1">
          <el-carousel-item v-for="item in proList" :key="item.id">
            <div class="img-car">
              <img width="100%" style="vertical-align: bottom" :src="item.path" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="details">
      <h1>{{ this.$t("deliveryx1.banner.title") }}</h1>
      <p>{{ this.$t("deliveryx1.banner.content") }}</p>
      <div class="box">
        <span>{{ this.$t("deliveryx1.banner.end") }}</span>
        <img src="../../assets/mobileDeliveryx1/award_RedDotWinner2022@2x.png" alt="" />
        <img
          src="../../assets/mobileDeliveryx1/award-a-design-2022-bronze@2x.png"
          alt=""
        />
      </div>
    </div>
    <div class="car-box">
      <el-carousel
        ref="carouselRef"
        indicator-position="none"
        arrow="never"
        :autoplay="false"
      >
        <el-carousel-item v-for="item in detialsList" :key="item.id">
          <img width="100%" :src="item.path" alt="" />
        </el-carousel-item>
      </el-carousel>

      <ul>
        <li @click="changeHandler(0)" :class="{ active: chooseId === 0 }">
          {{ this.$t("deliveryx1.details.content_title.title_a") }}
        </li>
        <li @click="changeHandler(1)" :class="{ active: chooseId === 1 }">
          {{ this.$t("deliveryx1.details.content_title.title_b") }}
        </li>
        <li @click="changeHandler(2)" :class="{ active: chooseId === 2 }">
          {{ this.$t("deliveryx1.details.content_title.title_c") }}
        </li>
      </ul>

      <h1 :class="{ act: chooseId === 0 }">
        {{ this.$t("deliveryx1.details.title_a") }}
      </h1>
      <h1 :class="{ act: chooseId === 1 }">
        {{ this.$t("deliveryx1.details.title_b") }}
      </h1>
      <h1 :class="{ act: chooseId === 2 }">
        {{ this.$t("deliveryx1.details.title_c") }}
      </h1>

      <!-- <h1>{{  }}</h1> -->
    </div>
    <div class="main">
      <h1>{{ this.$t("deliveryx1.details.content.title") }}</h1>
      <ul class="main-list">
        <li>
          <div class="list-img">
            <img src="../../assets/mobileDeliveryx1/one.png" alt="" />
          </div>
          <h2>{{ this.$t("deliveryx1.details.content.boxA.title") }}</h2>
          <div class="list-content">
            <span></span>
            <p>{{ this.$t("deliveryx1.details.content.boxA.content") }}</p>
          </div>
        </li>
        <li>
          <div class="list-img">
            <img src="../../assets/mobileDeliveryx1/two.png" alt="" />
          </div>
          <h2>{{ this.$t("deliveryx1.details.content.boxB.title") }}</h2>
          <div class="list-content">
            <span></span>
            <p>{{ this.$t("deliveryx1.details.content.boxB.content") }}</p>
          </div>
        </li>
        <li>
          <div class="list-img">
            <img src="../../assets/mobileDeliveryx1/three.png" alt="" />
          </div>
          <h2>{{ this.$t("deliveryx1.details.content.boxC.title") }}</h2>
          <div class="list-content">
            <span></span>
            <p>{{ this.$t("deliveryx1.details.content.boxC.content") }}</p>
          </div>
        </li>
        <li>
          <div class="list-img">
            <img src="../../assets/mobileDeliveryx1/four.png" alt="" />
          </div>
          <h2>{{ this.$t("deliveryx1.details.content.boxD.title") }}</h2>
          <div class="list-content">
            <span></span>
            <p>{{ this.$t("deliveryx1.details.content.boxD.content") }}</p>
          </div>
        </li>
        <li>
          <div class="list-img">
            <img src="../../assets/mobileDeliveryx1/five.png" alt="" />
          </div>
          <h2>{{ this.$t("deliveryx1.details.content.boxE.title") }}</h2>
          <div class="list-content">
            <span></span>
            <p>{{ this.$t("deliveryx1.details.content.boxE.content") }}</p>
          </div>
        </li>
        <li>
          <div class="list-img">
            <img src="../../assets/mobileDeliveryx1/six.png" alt="" />
          </div>
          <h2>{{ this.$t("deliveryx1.details.content.boxF.title") }}</h2>
          <div class="list-content">
            <span></span>
            <p>{{ this.$t("deliveryx1.details.content.boxF.content") }}</p>
          </div>
        </li>
      </ul>
    </div>
    <video
      width="100%"
      controls
      autoplay
      muted
      loop
      playsinline
      webkit-playsinline
      x5-playsinline
      src="../../assets/Deliveryx1Video/Delivery-X1-Pro-Video.mp4"
    ></video>
    <ul class="intro">
      <li>
        <span>{{ this.$t("deliveryx1.intro.boxA.header") }}</span>
        <h1>{{ this.$t("deliveryx1.intro.boxA.title") }}</h1>
        <p>{{ this.$t("deliveryx1.intro.boxA.content") }}</p>
        <ul>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxA_list.one") }}</p>
          </li>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxA_list.two") }}</p>
          </li>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxA_list.three") }}</p>
          </li>
        </ul>
        <video
          width="100%"
          controls
          autoplay
          muted
          loop
          playsinline
          webkit-playsinline
          x5-playsinline
          src="../../assets/Deliveryx1Video/Bring_productivity_up_and_costs_down.mp4"
        ></video>
      </li>
      <li>
        <span>{{ this.$t("deliveryx1.intro.boxB.header") }}</span>
        <h1>{{ this.$t("deliveryx1.intro.boxB.title") }}</h1>
        <p>{{ this.$t("deliveryx1.intro.boxB.content") }}</p>
        <ul>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxB_list.one") }}</p>
          </li>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxB_list.two") }}</p>
          </li>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxB_list.three") }}</p>
          </li>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxB_list.four") }}</p>
          </li>
        </ul>
        <video
          width="100%"
          controls
          autoplay
          muted
          loop
          playsinline
          webkit-playsinline
          x5-playsinline
          src="../../assets/Deliveryx1Video/Simplify_your_service_process.mp4"
        ></video>
      </li>
      <li>
        <span>{{ this.$t("deliveryx1.intro.boxC.header") }}</span>
        <h1>{{ this.$t("deliveryx1.intro.boxC.title") }}</h1>
        <p>{{ this.$t("deliveryx1.intro.boxC.content") }}</p>
        <ul>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxC_list.one") }}</p>
          </li>
          <li>
            <span></span>
            <p>{{ this.$t("deliveryx1.intro.boxC_list.two") }}</p>
          </li>
        </ul>
        <video
          width="100%"
          controls
          autoplay
          muted
          loop
          playsinline
          webkit-playsinline
          x5-playsinline
          src="../../assets/Deliveryx1Video/Collision-free-and-Spill-proof.mp4"
        ></video>
      </li>
    </ul>
    <div class="footer">
      <img width="100%" src="../../assets/mobileDeliveryx1/footer.png" alt="" />
      <h1>{{ this.$t("deliveryx1.footer.title") }}</h1>
      <ul>
        <li>
          <span>{{ this.$t("deliveryx1.footer.list.title_a") }}</span>
          <div>
            <p style="margin-bottom: 3.7333vw">
              {{ this.$t("deliveryx1.footer.list.content_a") }}
            </p>
            <p>{{ this.$t("deliveryx1.footer.list.content_aa") }}</p>
          </div>
        </li>
        <li>
          <span>{{ this.$t("deliveryx1.footer.list.title_b") }}</span>
          <p>{{ this.$t("deliveryx1.footer.list.content_b") }}</p>
        </li>
        <li>
          <span>{{ this.$t("deliveryx1.footer.list.title_c") }}</span>
          <p>{{ this.$t("deliveryx1.footer.list.content_c") }}</p>
        </li>
        <li>
          <span>{{ this.$t("deliveryx1.footer.list.title_d") }}</span>
          <p>{{ this.$t("deliveryx1.footer.list.content_d") }}</p>
        </li>
        <li>
          <span>{{ this.$t("deliveryx1.footer.list.title_e") }}</span>
          <p>{{ this.$t("deliveryx1.footer.list.content_e") }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeId: 0,
      chooseId: 0,
      x1List: [
        { id: 1, path: require("../../assets/Deliveryx1/x1-one.png") },
        { id: 2, path: require("../../assets/Deliveryx1/x1-two.png") },
        { id: 3, path: require("../../assets/Deliveryx1/x1-three.png") },
        { id: 4, path: require("../../assets/Deliveryx1/x1-four.png") },
        { id: 5, path: require("../../assets/Deliveryx1/x1-five.png") },
      ],
      proList: [
        { id: 1, path: require("../../assets/Deliveryx1/pro-one.png") },
        { id: 2, path: require("../../assets/Deliveryx1/pro-two.png") },
        { id: 3, path: require("../../assets/Deliveryx1/pro-three.png") },
        { id: 4, path: require("../../assets/Deliveryx1/pro-four.png") },
        { id: 5, path: require("../../assets/Deliveryx1/pro-five.png") },
      ],
      detialsList: [
        {
          id: 1,
          name: "one",
          path: require("../../assets/mobileDeliveryx1/details-one.png"),
        },
        {
          id: 2,
          name: "two",
          path: require("../../assets/mobileDeliveryx1/details-two.png"),
        },
        {
          id: 3,
          name: "three",
          path: require("../../assets/mobileDeliveryx1/details-three.png"),
        },
      ],
    };
  },
  methods: {
    changeHandler(index) {
      this.chooseId = index;
      this.setActiveItem(index);
    },
    setActiveItem(index) {
      this.$refs.carouselRef.setActiveItem(index);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 56vw;
  background: url("../../assets/mobileDeliveryx1/header.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8vw;
  box-sizing: border-box;
  h1 {
    font-size: 5.3333vw;
    font-family: "Meiryooo";
    font-weight: 400;
    line-height: 8vw;
    color: #ffffff;
    margin-bottom: 3.2vw;
  }
  p {
    font-size: 12px;
    font-family: "Meiryooo";
    font-weight: 400;
    line-height: 18px;
    color: #ffffff;
  }
}
.banner {
  ul {
    width: 100%;
    display: flex;
    font-size: 3.2vw;
    font-family: "Meiryooo";
    font-weight: 400;
    line-height: 10.6667vw;
    justify-content: center;
    color: #666666;
    margin-bottom: 5.3333vw;
    li {
      cursor: pointer;
      text-align: center;
      width: 21.0667vw;
      flex-shrink: 0;
      margin: 0 4vw;
      border-bottom: 0.5333vw solid transparent;
    }
    .active {
      color: #000000;
      border-color: #0064e6;
    }
  }
  /deep/.el-carousel {
    // height: 88vw !important;
    .el-carousel__container {
      width: 100%;
      height: 79vw;
    }
    .img-car {
      text-align: center;
    }
    .el-carousel__button {
      margin-top: 5.3333vw;
      width: 2.6667vw;
      height: 2.6667vw;
      border-radius: 2.6667vw;
      background-color: #6a6a6a;
    }
  }
}
.details {
  box-sizing: border-box;
  padding: 0 4.2667vw;
  margin: 8vw 0 21.3333vw 0;
  h1 {
    font-size: 5.3333vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 8vw;
    color: #333333;
    margin-bottom: 5.3333vw;
  }
  p {
    font-size: 3.2vw;
    font-family: "Meiryooo";
    font-weight: 400;
    line-height: 6.4vw;
    color: #333333;
    margin-bottom: 10.6667vw;
  }
  .box {
    display: flex;
    align-items: center;
    span {
      font-size: 3.7333vw;
      font-family: "Meiryooo";
      font-weight: 400;
      line-height: 5.0667vw;
      color: #000000;
      margin-left: 8vw;
    }
    img:nth-child(2) {
      width: 21.8667vw;
      margin-left: 5.3333vw;
    }
    img:last-child {
      width: 8vw;
    }
  }
}
.car-box {
  position: relative;
  box-sizing: border-box;
  background: pink;
  /deep/.el-carousel {
    // height: 88vw !important;
    .el-carousel__container {
      width: 100%;
      height: 79vw;
    }
    .img-car {
      text-align: center;
    }
    .el-carousel__button {
      margin-top: 5.3333vw;
      width: 2.6667vw;
      height: 2.6667vw;
      border-radius: 2.6667vw;
      background-color: #6a6a6a;
    }
  }
  ul {
    position: absolute;
    bottom: 0;
    z-index: 999;

    padding: 0 4.2667vw;
    display: flex;
    align-content: center;
    li {
      margin-right: 8vw;
      border-bottom: 0.5333vw solid transparent;
      font-size: 3.2vw;
      font-family: "Meiryooo";
      font-weight: 400;
      line-height: 10.6667vw;
      color: #ffffff;
    }
    .active {
      border-color: #0064e6;
    }
  }
  h1 {
    position: absolute;
    top: 16vw;
    z-index: 999;
    padding: 0 4.2667vw;
    font-size: 4.8vw;
    font-family: Meiryo;
    font-weight: bold;
    line-height: 7.2vw;
    color: #ffffff;
    opacity: 0;
    transition: 1s;
  }
  .act {
    opacity: 1;
  }
}
.main {
  h1 {
    font-size: 4.8vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 7.2vw;
    color: #000000;
    margin: 16vw 0 8vw 0;
    text-align: center;
  }
  ul {
    padding: 0 4.2667vw;
    box-sizing: border-box;
    li {
      height: 53.3333vw;
      padding: 0 5.3333vw;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .list-img {
        width: 11.2vw;
        height: 11.2vw;
        margin: 5.0667vw 0 4.8vw 0;
        img {
          width: 100%;
        }
      }

      h2 {
        font-size: 3.7333vw;
        font-family: "Meiryooo";
        font-weight: bold;
        line-height: 5.6vw;
        color: #000000;
        margin-bottom: 5.8667vw;
      }
      .list-content {
        display: flex;
        span {
          width: 0;
          height: 0;
          flex-shrink: 0;
          border: 0.8vw solid transparent;
          border-top-color: #051ad0;
          border-left-color: #051ad0;
          margin-right: 2.6667vw;
        }
        p {
          width: 73.6vw;
          font-size: 3.2vw;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 5.8667vw;
          color: #000000;
        }
      }
    }
    li:first-child {
      img {
        width: 10.6667vw;
      }
    }
    li:nth-child(4) {
      img {
        width: 9.0667vw;
      }
    }
    li:nth-child(5) {
      img {
        width: 8vw;
      }
    }
  }
}
video {
  vertical-align: bottom;
}
.intro {
  margin-bottom: 10.6667vw;
  li {
    padding: 0 4.2667vw 0 4.2667vw;
    padding-top: 16vw;
    span:first-child {
      font-size: 3.2vw;
      font-family: "Meiryo";
      font-weight: 400;
      line-height: 4.8vw;
      color: #333333;
    }
    h1 {
      font-size: 5.3333vw;
      font-family: "Meiryooo";
      font-weight: bold;
      line-height: 8vw;
      color: #000000;
      margin: 1.8667vw 0 2.1333vw 0;
    }
    p:nth-child(3) {
      font-size: 3.2vw;
      font-family: "Meiryooo";
      font-weight: 400;
      line-height: 6.4vw;
      color: #333333;
    }
    ul {
      margin-top: 5.3333vw;
      li {
        padding: 0;
        display: flex;
        margin-bottom: 4.2667vw;
        height: 4.8vw;
        span {
          width: 0;
          height: 0;
          border: 0.8vw solid transparent;
          border-top-color: #051ad0;
          border-left-color: #051ad0;
          margin-right: 3.2vw;
        }
        p {
          font-size: 3.2vw;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 1.2;
          color: #333333;
        }
      }
    }
  }
}
.footer {
  padding: 0 4.2667vw;
  margin-bottom: 10.6667vw;
  img {
    vertical-align: bottom;
  }
  h1 {
    text-align: center;
    margin: 4vw 0;
    font-size: 3.7333vw;
    font-family: "Meiryooo";
    font-weight: bold;
    line-height: 5.0667vw;
    color: #000000;
  }
  ul {
    li {
      display: flex;
      margin-bottom: 3.2vw;
      span {
        text-align: right;
        flex-shrink: 0;
        white-space: nowrap;
        width: 23.4667vw;
        font-size: 3.2vw;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 4.2667vw;
        color: #000000;
        margin-right: 2.6667vw;
      }
      p {
        font-size: 3.2vw;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 4.2667vw;
        color: #0064e6;
      }
    }
  }
}
</style>
